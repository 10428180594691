const bar_line_options = (themes, tempDataType, measures, series, text) => ({

  title: {
    text,
    x: '20',
    top: '20',
    textStyle: {
      color: themes.accent,
      fontSize: '22',
    },
    subtextStyle: {
      color: themes.primary,
      fontSize: '16',
    },
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      textStyle: {
        color: themes.accent,
      },
    },
  },
  grid: {
    left: '5%',
    right: '5%',
    borderWidth: 0,
    top: 150,
    bottom: 95,
    textStyle: {
      color: themes.accent,
    },
  },
  legend: {
    x: '5%',
    top: '10%',
    textStyle: {
      color: themes.primary,
    },
    data: measures,
  },
  calculable: true,
  xAxis: [{
    type: 'category',
    axisLine: {
      lineStyle: {
        color: '#90979c',
      },
    },
    splitLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitArea: {
      show: false,
    },
    axisLabel: {
      interval: 0,
    },
    axisLabel: {
      interval: 0,
      rotate: 90,
    },
    data: tempDataType,
  }],
  yAxis: [{
    type: 'value',
    splitLine: {
      show: false,
    },
    axisLine: {
      lineStyle: {
        color: '#90979c',
      },
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      interval: 0,
    },
    splitArea: {
      show: false,
    },
  }],
  dataZoom: [{
    show: true,
    height: 30,
    xAxisIndex: [0],
    bottom: 30,
    start: 0,
    end: 100,
    handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    handleSize: '110%',
    handleStyle: {
      color: '#d3dee5',
    },
    textStyle: {
      color: '#fff',
    },
    borderColor: '#90979c',
  },
  {
    type: 'inside',
    show: true,
    height: 15,
    start: 1,
    end: 35,
  },
  ],
  series,
});

const _line_opt_ = (tempDataType, measures, series, _measures) => ({
  title: {
    text: '',
  },
  tooltip: {
    trigger: 'axis',
    textStyle: {
      fontSize: 11,
    },

    formatter(params) {
      let text = `${params[0].axisValueLabel}<br>`;
      for (let i = 0; i < params.length; i++) {
        const {
          unit,
        } = _measures[params[i].seriesName];
        const text1 = _measures[params[i].seriesName].text;
        const v = parseFloat(params[i].value);
        text += `${text1}: ${v} ${unit} <br>`;
      }

      return text;
    },
  },
  legend: {
    data: measures,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {
        title: 'Ekran Kayıt',
      },
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: tempDataType,
  },
  yAxis: {
    type: 'value',

  },
  dataZoom: [{
    type: 'inside',
    start: 0,
    end: 500,
  },
  {
    start: 50,
    end: 200,
    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
    handleSize: '80%',
    handleStyle: {
      color: '#fff',
      shadowBlur: 3,
      shadowColor: 'rgba(0, 0, 0, 0.6)',
      shadowOffsetX: 2,
      shadowOffsetY: 2,
    },
  },
  ],
  series,
});

const line_opt = (tempDataType, measures, series) => ({
  title: {
    text: '',
  },
  tooltip: {
    trigger: 'axis',
    textStyle: {
      fontSize: 11,
    },

    formatter(params) {
      let text = `${params[0].axisValueLabel}<br>`;
      for (let i = 0; i < params.length; i++) {
        text += `${params[i].seriesName}：${params[i].value.toFixed(2)} A <br>`;
      }
      return text;
    },
  },
  legend: {
    data: measures,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {
        title: 'Ekran Kayıt',
      },
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: tempDataType,
  },
  yAxis: {
    type: 'value',

  },
  dataZoom: [{
    type: 'inside',
    start: 0,
    end: 500,
  },
  {
    start: 50,
    end: 200,
    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
    handleSize: '80%',
    handleStyle: {
      color: '#fff',
      shadowBlur: 3,
      shadowColor: 'rgba(0, 0, 0, 0.6)',
      shadowOffsetX: 2,
      shadowOffsetY: 2,
    },
  },
  ],
  series,
});

const line_opt_right = (tempDataType, measures, series) => ({
  title: {
    text: '',
  },
  tooltip: {
    trigger: 'axis',
    textStyle: {
      fontSize: 11,
    },

    formatter(params) {
      let text = `${params[0].axisValueLabel}<br>`;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < params.length; i++) {
        if (params[i].seriesName === 'DCVoltage') {
          text += `${params[i].seriesName}：${params[i].value.toFixed(2)} V <br>`;
        } else {
          text += `${params[i].seriesName}：${params[i].value.toFixed(2)} A <br>`;
        }
      }

      return text;
    },
  },
  legend: {
    data: measures,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {
        title: 'Ekran Kayıt',
      },
    },
  },
  xAxis: [{
    type: 'category',
    data: tempDataType,
  }],

  yAxis: {
    type: 'value',
  },
  dataZoom: [{
    type: 'inside',
    start: 0,
    end: 500,
  },
  {
    start: 50,
    end: 200,
    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
    handleSize: '80%',
    handleStyle: {
      color: '#fff',
      shadowBlur: 3,
      shadowColor: 'rgba(0, 0, 0, 0.6)',
      shadowOffsetX: 2,
      shadowOffsetY: 2,
    },
  },
  ],
  series,
});
const line_option_empty = (title, measureList, xAxisData) => ({

  title: {
    text: title,
    left: 'left',
    textStyle: {
      fontSize: 14,
      align: 'center',
    },
    bottom: -8,
  },

  tooltip: {
    trigger: 'axis',
    axisPointer: { // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },

  },
  legend: {
    data: measureList,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: xAxisData,
  },
  yAxis: {
    type: 'value',

  },

  series: [],
});

const line_option = (tempDataType, series, title, measureList, colors = null) => ({
  color: colors,
  title: {
    text: title,
    textStyle: {
      fontSize: 14,
      align: 'center',
    },
    left: 'center',
    bottom: -8,
  },

  tooltip: {
    trigger: 'axis',
    axisPointer: { // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },
    formatter(params) {
      let text = `${params[0].axisValueLabel}<br>`;
      const inveterVal = params[0].value + params[1].value;
      text += `${params[0].seriesName}：${params[0].value.toFixed(2)} A <br>`;
      text += `${params[1].seriesName}：${inveterVal.toFixed(2)} A <br>`;

      return text;
    },
  },
  legend: {
    data: measureList,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: tempDataType,
  },
  yAxis: {
    type: 'value',

  },
  series,
});

const line_options = (tempDataType, measures, series, colors = []) => ({
  color: colors,

  title: {
    text: measures.text,
    left: 'center',
  },
  tooltip: {
    trigger: 'axis',
    textStyle: {
      fontSize: 11,
    },

    formatter(params) {
      let text = `${params[0].axisValueLabel}<br>`;
      for (let i = 0; i < params.length; i++) {
        const v = parseFloat(params[i].value);
        text += `${params[i].seriesName}：${v.toFixed(2)} ${measures.unit} <br>`;
      }

      return text;
    },
  },
  legend: {
    data: measures.value,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {
        title: 'Ekran Kayıt',
      },
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: tempDataType,
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: `{value} ${measures.unit}`,
    },
  },
  dataZoom: [{
    type: 'inside',
    start: 0,
    end: 500,
  },
  {
    start: 50,
    end: 200,
    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
    handleSize: '80%',
    handleStyle: {
      color: '#fff',
      shadowBlur: 3,
      shadowColor: 'rgba(0, 0, 0, 0.6)',
      shadowOffsetX: 2,
      shadowOffsetY: 2,
    },
  },
  ],
  series,
});

const line_options_multi = (text, measures, tempDataType, series, colors, yAxis) => ({
  color: colors,
  title: {
    text,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985',
      },
    },
  },
  toolbox: {
    feature: {
      saveAsImage: {
        title: 'Ekran Kayıt',
      },
    },
  },
  legend: {
    data: measures,
  },

  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [{
    type: 'category',
    boundaryGap: false,
    data: tempDataType,
  }],
  yAxis: [{
    type: 'value',
  }],
  dataZoom: [{
    show: true,
    height: 5,
    xAxisIndex: [0],
    bottom: 30,
    start: 0,
    end: 200,
    handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    handleSize: '110%',
    handleStyle: {
      color: '#d3dee5',
    },
    textStyle: {
      color: '#fff',
    },
    borderColor: '#90979c',
  },
  {
    type: 'inside',
    show: true,
    height: 15,
    start: 1,
    end: 35,
  },

  ],
  series,
});

const line_options_multie_1 = (text, measures, tempDataType, series, colors, yAxis) => ({
  color: colors,

  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
  grid: {
    right: '20%',
  },
  toolbox: {
    feature: {
      dataView: {
        show: true,
        readOnly: false,
        title: 'Veri',

      },
      restore: {
        show: true,
        title: 'Yenile',

      },
      saveAsImage: {
        show: true,
        title: 'Ekran Kayıt',
      },

    },
  },
  legend: {
    data: measures,
  },
  xAxis: [{
    type: 'category',
    axisTick: {
      alignWithLabel: true,
    },
    data: tempDataType,
  }],
  yAxis,
  series,

});

const line_options_multies = (text, measures, tempDataType, series, colors, yAxis) => {
  const posList = [
    'left', 'right', 'top', 'bottom',
    'inside',
    'insideTop', 'insideLeft', 'insideRight', 'insideBottom',
    'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight',
  ];

  app.configParameters = {
    rotate: {
      min: -90,
      max: 90,
    },
    align: {
      options: {
        left: 'left',
        center: 'center',
        right: 'right',
      },
    },
    verticalAlign: {
      options: {
        top: 'top',
        middle: 'middle',
        bottom: 'bottom',
      },
    },
    position: {
      options: posList.reduce((map, pos) => {
        map[pos] = pos;
        return map;
      }, {}),
    },
    distance: {
      min: 0,
      max: 100,
    },
  };

  app.config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    onChange() {
      const labelOption = {
        normal: {
          rotate: app.config.rotate,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          position: app.config.position,
          distance: app.config.distance,
        },
      };
      myChart.setOption({
        series: [{
          label: labelOption,
        }, {
          label: labelOption,
        }, {
          label: labelOption,
        }, {
          label: labelOption,
        }],
      });
    },
  };

  const labelOption = {
    show: true,
    position: app.config.position,
    distance: app.config.distance,
    align: app.config.align,
    verticalAlign: app.config.verticalAlign,
    rotate: app.config.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 16,
    rich: {
      name: {},
    },
  };
  return {
    color: colors,
    title: {
      text,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: measures,
    },

    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: {
          show: true,
          title: 'mark',
        },

        magicType: {
          show: true,
          type: ['line', 'bar', 'stack', 'tiled'],
          title: 'Tip',
        },
        dataView: {
          show: true,
          readOnly: false,
          title: 'Veri',

        },
        restore: {
          show: true,
          title: 'Yenile',

        },
        saveAsImage: {
          show: true,
          title: 'Ekran Kayıt',
        },
      },
    },
    grid: {
      right: '18%',
      left: '10%',
    },
    xAxis: [{
      type: 'category',
      axisTick: {
        alignWithLabel: true,
      },
      data: tempDataType,
    }],
    yAxis,
    series,
    dataZoom: [{
      show: true,
      height: 10,
      xAxisIndex: [0],
      bottom: 30,
      start: 0,
      end: 200,
      handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
      handleSize: '110%',
      handleStyle: {
        color: '#d3dee5',
      },
      textStyle: {
        color: '#fff',
      },
      borderColor: '#90979c',
    },
    {
      type: 'inside',
      show: true,
      height: 10,
      start: 1,
      end: 35,
    },

    ],

  };
};

const line_options_multies_1 = (text, measures, tempDataType, series, colors, yAxis) => {

  return {
    color: colors,
    title: {
      text,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        fontSize: 11,
      },
    },
    legend: {
      data: measures,
      bottom: 0,
      left: 'center',
    },

    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: {
          show: true,
          title: 'mark',
        },

        magicType: {
          show: true,
          type: ['line', 'bar', 'stack', 'tiled'],
          title: 'Tip',
        },
        dataView: {
          show: true,
          readOnly: false,
          title: 'Veri',

        },
        restore: {
          show: true,
          title: 'Yenile',

        },
        saveAsImage: {
          show: true,
          title: 'Ekran Kayıt',
        },
      },
    },
    grid: {
      right: '5%',
    },
    xAxis: [{
      type: 'category',
      axisTick: {
        alignWithLabel: true,
      },
      data: tempDataType,
    }],
    yAxis,
    series,
    dataZoom: [{
      show: true,
      height: 10,
      xAxisIndex: [0],
      bottom: 30,
      start: 0,
      end: 200,
      handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
      handleSize: '110%',
      handleStyle: {
        color: '#d3dee5',
      },
      textStyle: {
        color: '#fff',
      },
      borderColor: '#90979c',
    },
    {
      type: 'inside',
      show: true,
      height: 10,
      start: 1,
      end: 35,
    },

    ],

  };
};

const multi_line_option = (tempDataType, measures, series, colors, yAxis) => ({
  color: colors,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
  grid: {
    right: '10%',
  },
  toolbox: {
    feature: {

      dataView: {
        show: true,
        readOnly: false,
        title: 'Veri',

      },
      restore: {
        show: true,
        title: 'Yenile',

      },
      saveAsImage: {
        show: true,
        title: 'Ekran Kayıt',
      },
    },
  },
  legend: {
    data: measures,
  },
  xAxis: [{
    type: 'category',
    axisTick: {
      alignWithLabel: true,
    },
    data: tempDataType,
  }],
  yAxis,
  dataZoom: [{
    type: 'inside',
    start: 0,
    end: 500,
  },
  {
    start: 50,
    end: 200,
    height: 10,
    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
    handleSize: '80%',
    handleStyle: {
      color: '#fff',
      shadowBlur: 3,
      shadowColor: 'rgba(0, 0, 0, 0.6)',
      shadowOffsetX: 2,
      shadowOffsetY: 2,
    },
  },
  ],
  series,
});

const gauge_optionsxs = (data, max, icons) => ({

  series: [{
    type: 'gauge',
    center: ['40%', '60%'],
    startAngle: 200,
    endAngle: -20,
    min: 0,
    max,
    splitNumber: 5,
    itemStyle: {
      color: '#FFAB91',
    },
    axisLine: {
      lineStyle: {
        width: 18,
        color: [
          [0.3, '#67e0e3'],
          [0.7, '#37a2da'],
          [1, '#fd666d'],
        ],
      },
    },
    progress: {
      length: 12,
    },
    pointer: {
      itemStyle: {
        color: 'auto',
      },
    },
    axisTick: {
      distance: -30,
      length: 8,
      lineStyle: {
        color: '#fff',
        width: 2,
      },
    },
    splitLine: {
      distance: -10,
      length: 20,
      lineStyle: {
        color: '#fff',
        width: 4,
      },
    },
    axisLabel: {
      color: 'auto',
      distance: -50,
      fontSize: 12,
    },

    detail: {
      valueAnimation: true,
      width: '70%',
      lineHeight: 40,
      height: '15%',
      borderRadius: 8,
      offsetCenter: [0, '45%'],
      fontSize: 12,
      fontWeight: 'bolder',
      formatter: `{value} ${icons}`,
      color: 'auto',
    },
    title: {
      offsetCenter: [0, '90%'],
      fontSize: 12,
      color: 'black',

    },
    data,
  }],
});

const gauge_options = (data, max, icons) => ({
  series: [{
    type: 'gauge',
    center: ['50%', '50%'],
    startAngle: 210,
    endAngle: -30,
    min: 0,
    max,
    splitNumber: 5,
    itemStyle: {
      color: '#FFAB91',
    },
    axisLine: {
      lineStyle: {
        width: 10,
        color: [
          [0.3, '#67e0e3'],
          [0.7, '#37a2da'],
          [1, '#fd666d'],
        ],
      },
    },
    progress: {
      length: 12,
    },
    pointer: {
      length: '70%',
      width: 4,
      itemStyle: {
        color: 'auto',
      },
    },
    axisTick: {
      distance: -10,
      length: 8,
      lineStyle: {
        color: '#fff',
        width: 2,
      },
    },
    splitLine: {
      distance: -30,
      length: 18,
      lineStyle: {
        color: '#fff',
        width: 4,
      },
    },
    axisLabel: {
      color: 'auto',
      distance: -10,
      fontSize: 12,
    },
    detail: {
      valueAnimation: true,
      width: '95%',
      lineHeight: 10,
      height: '15%',
      borderRadius: 8,
      offsetCenter: [0, '65%'],
      fontSize: 14,
      fontWeight: 'bolder',
      formatter: `{value} ${icons}`,
      color: 'auto',
    },
    title: {
      offsetCenter: [0, '10%'],
      fontSize: 14,
      color: 'black',
    },
    data,
  }],
});

const gauge_optionsxss = (data, max, icons) => ({
  series: [{
    type: 'gauge',
    center: ['50%', '50%'],
    startAngle: 200,
    endAngle: -20,
    min: 0,
    max,
    splitNumber: 5,
    itemStyle: {
      color: '#FFAB91',
    },
    axisLine: {
      lineStyle: {
        width: 15,
        color: [
          [0.3, '#67e0e3'],
          [0.7, '#37a2da'],
          [1, '#fd666d'],
        ],
      },
    },
    progress: {
      length: 12,
    },
    pointer: {
      length: '70%',
      width: 4,
      itemStyle: {
        color: 'auto',
      },
    },
    axisTick: {
      distance: -30,
      length: 10,
      lineStyle: {
        color: '#fff',
        width: 2,
      },
    },
    splitLine: {
      distance: -27,
      length: 18,
      lineStyle: {
        color: '#fff',
        width: 4,
      },
    },
    axisLabel: {
      color: 'auto',
      distance: -45,
      fontSize: 12,
    },

    detail: {
      valueAnimation: true,
      width: '85%',
      lineHeight: 40,
      height: '15%',
      borderRadius: 8,
      offsetCenter: [0, '45%'],
      fontSize: 14,
      fontWeight: 'bolder',
      formatter: `{value} ${icons}`,
      color: 'auto',
    },
    title: {
      offsetCenter: [0, '90%'],
      fontSize: 14,
      color: 'black',
    },
    data,
  }],
});
const gauge_optionsx = (data, max, icons) => ({
  series: [{
    type: 'gauge',
    min: 0,
    max,
    anchor: {
      show: true,
      showAbove: true,
      size: 18,
      itemStyle: {
        color: '#FAC858',
      },
    },
    pointer: {
      icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
      width: 8,
      length: '80%',
      offsetCenter: [0, '8%'],
    },

    progress: {
      show: true,
      overlap: true,
      roundCap: true,
    },
    axisLine: {
      roundCap: true,
    },
    data,
    title: {
      fontSize: 14,
    },
    detail: {
      width: 40,
      height: 14,
      fontSize: 14,
      color: '#fff',
      backgroundColor: 'auto',
      borderRadius: 3,
      formatter: `{value} ${icons}`,
    },
  }],
});

const gaugeOpt = (data, max, icons) => ({
  series: [{
    type: 'gauge',
    progress: {
      show: true,
      width: 18,
    },
    axisLine: {
      lineStyle: {
        width: 18,
      },
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      length: 15,
      lineStyle: {
        width: 2,
        color: '#999',
      },
    },
    axisLabel: {
      distance: 25,
      color: '#999',
      fontSize: 20,
    },
    anchor: {
      show: true,
      showAbove: true,
      size: 25,
      itemStyle: {
        borderWidth: 10,
      },
    },
    title: {
      show: false,
    },
    detail: {
      valueAnimation: true,
      fontSize: 80,
      offsetCenter: [0, '70%'],
    },
    data: [{
      value: 70,
    }],
  }],
});
const echartHeatmapOptions = (series, xAxisData, yAxisData, min, max, grid, tooltip, myChart) => {
 
  const option = {
    tooltip,
    /* title: {
        top: 10,
        left: 'botton',
        position:'botton',
        text: title
    }, */
    grid,
    xAxis: {
      type: 'category',
      data: xAxisData,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max,
      calculable: true,
      orient: 'vertical',
      right: 10,
      top: 'center',
      color: 'black',

    },
    series,
  };

  option && myChart.setOption(option);
};
const echartHeatmapOptionsxe = (series, xAxisData, yAxisData, min, max, grid, tooltip, myChart) => {
  const option = {
    tooltip,
    /* title: {
        top: 10,
        left: 'botton',
        position:'botton',
        text: title
    }, */
    grid,
    xAxis: {
      type: 'category',
      data: xAxisData,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min,
      max,
      calculable: true,
      orient: 'vertical',
      right: 10,
      top: 'center',
      color: 'black',

    },
    series,
  };

  option && myChart.setOption(option);
};

const echartHeatmap_Options = (series, xAxisData, yAxisData, min, max, grid, tooltip, show =
true) => ({
  tooltip,
  /* title: {
        top: 10,
        left: 'botton',
        position:'botton',
        text: title
    }, */
  grid,
  xAxis: {
    type: 'category',
    data: xAxisData,
    splitArea: {
      show: true,
    },
  },
  yAxis: {
    type: 'category',
    data: yAxisData,
    splitArea: {
      show: true,
    },
  },
  visualMap: {
    min,
    max,
    calculable: true,
    orient: 'vertical',
    right: 10,
    top: 'center',
    color: 'black',
    calculable: true,
    realtime: false,
    show,
    bottom: '10%',
    inRange: {
      color: [
        '#f03a2e',
        '#23a643',
      ],
    },
  },
  series,
});

const line_opt_right_ss = (colors, measures, dateList, yAxis, series) => ({
  color: colors,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
  grid: {
    right: '20%',
  },
  toolbox: {
    feature: {

      dataView: {
        show: true,
        readOnly: false,
        title: 'Veri',
      },
      restore: {
        show: true,
        title: 'Yenile',
      },
      saveAsImage: {
        show: true,
        title: 'Ekran Kayıt',
      },
    },
  },
  legend: {
    data: measures,
  },
  xAxis: [{
    type: 'category',
    axisTick: {
      alignWithLabel: true,
    },
    data: dateList,
  }],
  yAxis,
  series,
  dataZoom: [{
    show: true,
    height: 10,
    xAxisIndex: [0],
    bottom: 30,
    start: 0,
    end: 200,
    handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    handleSize: '110%',
    handleStyle: {
      color: '#d3dee5',
    },
    textStyle: {
      color: '#fff',
    },
    borderColor: '#90979c',
  },
  {
    type: 'inside',
    show: true,
    height: 10,
    start: 1,
    end: 35,
  },

  ],
});
export default {
  gaugeOpt,
  line_opt_right_ss,
  _line_opt_,
  // gauge_opt,
  line_options_multie_1,
  gauge_options,
  gauge_optionsx,
  line_options_multi,
  bar_line_options,
  line_options,
  line_opt,
  line_opt_right,
  line_option,
  echartHeatmapOptions,
  multi_line_option,
  line_options_multies,
  line_options_multies_1,
  echartHeatmap_Options,
  line_option_empty,
};
